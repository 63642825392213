/**
 * Format Game & DLC Related data
 */
export function getGameData(data) {
  const gameData = data.game ? data.game.edges[0].node : null;
  const promoData = data.defaultGame?.sales ? data.defaultGame?.sales : null;
  const stores = data.defaultGame?.stores ? data.defaultGame?.stores : null;
  const storeLogos = data.storeLogos?.edges;

  const storesData = [];
  stores.forEach((store) => {
    let { platformID } = store;
    if (platformID === 'switch') {
      platformID = 'nintendo-switch';
    } else if (platformID === 'epic') {
      platformID = 'epic-games-store';
    }
    // Get default store logos
    let defaultSmallLogo = '';
    let defaultLargeLogo = '';
    let defaultAltLogo = '';
    storeLogos.forEach((logo) => {
      const currentSmallLogo = `${platformID}-logo-small`;
      const currentLargeLogo = `${platformID}-logo`;
      const currentAltLogo = `${platformID}-logo-alt`;
      const { name } = logo.node;
      if (currentSmallLogo === name) {
        defaultSmallLogo = logo.node;
      }
      if (currentLargeLogo === name) {
        defaultLargeLogo = logo.node;
      }
      if (currentAltLogo === name) {
        defaultAltLogo = logo.node;
      }
    });
    const storeData = {
      ...store,
      logoLarge: store?.logoLarge ? store.logoLarge : defaultLargeLogo,
      logoSmall: store?.logoSmall ? store.logoSmall : defaultSmallLogo,
      logoAlternate: store?.logoAlternate ? store.logoAlternate : defaultAltLogo,
    };
    storesData.push(storeData);
  });

  // Game Stores links
  const gameLinks = [];
  const mainGame = gameData.baseGame ? gameData.baseGame : null;
  mainGame.forEach((product) => {
    gameLinks.push({ ...product, type: 'main-game' });
  });

  // CTA data
  const buySection = gameData.buyGameSection ? gameData.buyGameSection : null;
  const buyPopup = gameData.buyGamePopup ? gameData.buyGamePopup : null;

  // Promotion Data
  const promoDiscounts = promoData !== null && promoData.sales ? promoData.sales : null;
  const promoFreeWE = promoData !== null && promoData.freeWeekEnd ? promoData.freeWeekEnd : null;

  const formatedGameData = {
    buySection,
    buyPopup,
    gameLinks,
    promos: {
      discounts: promoDiscounts,
      freeWE: promoFreeWE,
    },
    stores: storesData,
  };
  return formatedGameData;
}
