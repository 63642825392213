/**
 * Format all general data
 */
import { getGameData } from './game-data';
import { getRatingData } from './rating-data';

export function getAllData(data, pageData) {
  const gameData = getGameData(data);
  const ratingsData = getRatingData(data);
  const brandData = data.brand?.edges?.[0]?.node ? data.brand?.edges?.[0]?.node : data.brand;

  // Global Data
  const defaultGlobalData = data.defaultGlobal;
  const localeGlobalData = data.global?.edges?.[0]?.node;
  const globalData = {
    ...localeGlobalData,
    socialLinks: defaultGlobalData.socialLinks,
    ageGate: { ...defaultGlobalData.ageGate, ...localeGlobalData.ageGate },
    footer: { ...defaultGlobalData.footer, ...localeGlobalData.footer },
    newsletterForm: { ...defaultGlobalData.newsletterForm, ...localeGlobalData.newsletterForm },
  };

  // Seo Data
  const defaultSeoData = data.defaultSeo;
  const pageSeoData = pageData?.pageData?.metadata;
  // const localeSeoData = data.seo?.edges?.[0]?.node
  const seoData = {
    ...defaultSeoData,
    metadata: { ...defaultSeoData.metadata, ...pageSeoData },
  };

  // Other data
  const navData = data.navigation ? data.navigation.edges[0].node : null;
  const stringsData = data.strings ? data.strings.edges[0].node : null;
  const directoriesData = data.pageDirectories ? data.pageDirectories.edges : null;

  const generalData = { gameData, ratingsData, brandData, globalData, navData, seoData, stringsData, directoriesData };
  return generalData;
}
