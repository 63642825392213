/**
 * Format theme data to include global site Theme and custom styling
 */
export function getThemeData(baseTheme, navData) {
  const siteTheme = baseTheme;
  const customTheme = {
    customStylingClass: navData.customStylingClass,
    customStylingState: navData.customStylingState,
    customStylingToggle: navData.customStylingToggle,
  };

  const themeData = { siteTheme, customTheme };
  return themeData;
}
