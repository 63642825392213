/**
 * Format Ratings Related data
 */
export function getRatingData(data) {
  const formatedRatingsData = {
    ratings: data.defaultGlobal?.ratings?.countryGroups,
    ratingLogos: data.ratingLogos?.edges,
  };
  return formatedRatingsData;
}
